import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import { InputSwitch } from 'primereact/inputswitch'
import { APP_FEP, PAYMENT_STATE } from '../constants'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getListInvoices } from '../service/InvoiceService'
import { moneyFormat } from '../core/service/CommonService'

export const MergeInvoice = (props) => {
    const trans = props.trans
    // const [isMergeInvoice, setMergeInvoice] = useState(false)
    const [loadTable, setLoadTable] = useState(false)
    const [filter, setFilter] = useState({
		searchValue: '',
		app: APP_FEP,
		statuses: [PAYMENT_STATE.awaiting],
		onlyParent: true
    })
    const [invoiceList, setInvoiceList] = useState([])

    const isMergeInvoice = props.isMergeInvoice
    const selectedInvoices = props.selectedInvoices
    
    useEffect(() => {
        if(loadTable)
            filterInvoices()
    }, [loadTable])
    
    const filterInvoices = () => {
        getListInvoices(filter)
        .then(res => {
            setInvoiceList(res)
            setLoadTable(false)
        })
    }

    const onChangeSelectedInvoice = (invoiceId, isSelected) => {
        let arr = [...selectedInvoices]

        if(isSelected){
            arr.push(invoiceId)
        }else{
            const removeIdx = selectedInvoices.findIndex(id => id===invoiceId);
            arr.splice(removeIdx, 1)
        }

        props.onchange({key: 'selectedInvoices', value: arr})
    }

    return (
        <div className="p-grid p-fluid form-group">
            {/* <div className="p-col-12">
                <label className="p-w-bold p-margin-right-20">{trans('res.menu.ask_merge_invoice')}</label>
                <InputSwitch checked={isMergeInvoice} onChange={(e) => props.onchange({key: 'isMerge', value: e.value})}/>
            </div> */}
            {isMergeInvoice && 
            <React.Fragment>
                <div className="p-col-12">
                    <label className="p-label">{trans('res.menu.find_invoices')} *</label>
                    <div className="p-inputgroup">
                        <InputText disabled={!isMergeInvoice} value={filter.searchValue} onChange={(e) => setFilter({...filter, searchValue: e.target.value}) } style={{width: "100%"}} placeholder={trans("res.menu.input_key_to_find_invoice")}/>
                        <Button disabled={!isMergeInvoice || !filter.searchValue} label={trans('res.menu.find')} icon="pi pi-check" onClick={(e) => setLoadTable(true)}/>
                        <Button disabled={!isMergeInvoice || !filter.searchValue} label={trans('res.menu.reset')} icon="pi pi-times" className="p-button-warning" onClick={(e) => {setFilter({...filter, searchValue: ''}); setInvoiceList([])}}/>
                    </div>
                </div>
                <div className="p-col-12">
                    <DataTable lazy={false} paginator={true} responsive={true}
                        header={trans('res.menu.invoice_list_header')}
                        value={invoiceList}
                        emptyMessage={trans('res.menu.no_invoice_available')}
                    >
                        <Column header={trans('res.menu.invoice_number')} field="id"/>
                        <Column header={trans('res.menu.payor')} 
                            body={rowData => {
                                return <React.Fragment>
                                    <div>{rowData.payorName} {rowData.payorEmail && ' - ' + rowData.payorEmail}</div>
                                    
                                    {rowData.payorPhone && <div className="p-margin-top-5">[{rowData.payorPhone}]</div>}
                                </React.Fragment>
                            }
                        }/>
                        <Column header={trans('res.menu.total')} field="total" body={(rowData) => moneyFormat(rowData.total)}/>
                        <Column header={trans('res.menu.due_amount')} field="checkout" body={(rowData) => moneyFormat(rowData.checkout)}/>
                        <Column header={trans('res.menu.paid')} field="paid" body={(rowData) => moneyFormat(rowData.paid)}/>
                        <Column header={trans('res.menu.select')} style={{ textAlign: "center", width: "12em"}} 
                            body={rowData => <InputSwitch checked={selectedInvoices.length > 0 && selectedInvoices.findIndex(num => num===rowData.id) !== -1} onChange={(e) => onChangeSelectedInvoice(rowData.id, e.value)} />}/>
                    </DataTable>
                </div>
            </React.Fragment>
            }
        </div>
    )
}