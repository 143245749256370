/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import classNames from 'classnames';
import { withNamespaces } from "react-i18next";
import './CustomerOrderTopbar.scss';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { QRCodeForm } from "../components/QRCodeForm";
import { TABLE_REQUEST_STATUS, USE_FOR_PURPOSE } from "../../constants";
import { DataScroller } from 'primereact/datascroller';
import Timer from "../components/Timer";
import { Menu } from "primereact/menu";
import { Icon } from "@iconify/react";

export class CustomerOrderTopbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageDialogVisible: false,
      language: this.props.cLang,
      logoLoaded: true,
      isTop: false,
      listNoti: this.props.listNoti ? this.props.listNoti : [],
      notiButton: false,
    };
  }

  componentDidMount(){
    this.props.setHeight(this.getTopbarHeight());
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    let currentHideNav = (window.innerWidth <= 400 && (this.props.table && this.props.table.requestStatus !== undefined && this.props.table.requestStatus !== null));
    if (currentHideNav !== this.state.topbarView) {
        this.setState({topbarView: currentHideNav}, () =>{
          this.props.setHeight(this.getTopbarHeight());
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  componentDidUpdate(prevProps) {
    if(Object.keys(prevProps.config).length === 0 && Object.keys(this.props.config).length > 0) {
      this.props.setHeight(this.getTopbarHeight());
    }
  }

  getFlagPath = (locale) => {
    if(locale === null) return;
    let style = this.props.flagStyle ? this.props.flagStyle : 'rectangle';
    return require('../../core/components/flags/flag-' + style + '-' + locale + '.png');
  }

  getTopbarHeight = () => {
    let addHeight = 0
    if(this.state.topbarView){
      addHeight = 45
    }
    const offset = this.topBar.clientHeight + 5 + addHeight
    return offset
  }

  getLangName = (key) => {
    switch (key) {
        case 'en-US':
            return 'English';
        case 'en-UK':
            return 'English';
        case 'fr-FR':
            return 'Français';
        case 'fi':
            return 'Suomi';
        case 'sv-SE':
            return 'Svenska';
        case 'de':
            return 'Deutsch';
        case 'vi':
            return 'Tiếng Việt';
        case 'es':
            return 'Español';
        case 'in':
            return 'Bahasa';
        case 'pt-PT':
            return 'Português';
        case 'pt-BR':
            return 'Português';
        case 'zh-CN':
            return '中文';
        case 'ja':
            return '日本語';
        case 'ko':
            return '한국어';
        case 'nl':
            return 'Dutch';
        case 'no':
            return 'Norwegian';
        case 'tr':
            return 'Turkish';
        case 'zh-TW':
            return 'Taiwanese';
        case 'da':
            return 'Danish';
        case 'ru':
          return 'русский язык';
        default:
            return '';
    }
}

  onClickRequestCheck = async () => {
    this.setState({ btnRequestCheckDisable: true });
    await this.props.requestCheck();
    this.setState({ btnRequestCheckDisable: false });
  }

  onClickCallWaiter = async () => {
    this.setState({ btnCallWaiterDisable: true });
    await this.props.onCallWaiter();
    this.setState({ btnCallWaiterDisable: false });
  }

  showNotis = () => {
    if(this.state.notiButton){
      this.setState({
        notiButton: false
      })
    }else{
      this.setState({
        notiButton: true
      })
    }
  }

  renderNotiDetail = (data) =>{
    const trans = this.props.trans;
    if(data.id === 0){
      return(
        <div className="p-col-12 p-c">
          <label>{trans('res.mgt.empty_list_notification')}</label>
        </div>
      )
    }else{
      return(
        <div className="p-col-12">
          <div className="p-grid p-fluid">
            <div className="p-col-8" style={{overflowX: "hidden"}}>
              <label >{data.content}</label>
            </div>
            <div className="p-col-4">
              <Timer time={data.createdAt} suffix={trans('res.mgt.ago')}/>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const trans = this.props.trans;
    const resConfig = this.props.config;

    const languages = localStorage.getItem('enabledLocales');
    const applicationUrl = localStorage.getItem('resApplicationUrl');
    const emptyNotifications = [{id:0}];

    return (
      <div className="layout-topbar customer" ref={(el) => this.topBar = el}>
        <Dialog className="lang-dialog" header={trans('res.menu.change_lang')} visible={this.state.languageDialogVisible} modal={true} onHide={() => this.setState({ languageDialogVisible: false })}
          footer="">
          <div className="p-grid p-fluid form-group">
            <div className="p-col-12">
              {languages && languages.split(',').map((option) => {
                return <div key={option} className="p-col-12 language-button">
                  <button style={{ display: "inline-flex", width: "100%" }} onClick={() => { this.props.changeLanguage(option); this.setState({ languageDialogVisible: false, cFlagImage: this.getFlagPath(option), language: option }) }}>
                    <img width={27} alt={this.state.activeLocale} src={this.getFlagPath(option)} />
                    <label style={{ margin: "auto", paddingLeft: "3px" }}>{this.getLangName(option)}</label>
                  </button>
                </div>
              })}
            </div>
          </div>
        </Dialog>

        <QRCodeForm ref={el => this.QRCodeForm=el} trans={trans} hideCode={true} hideUrl={true}/>

        <div className={"topbar-action " + (this.props.isOrderPage && this.props.table && this.props.table.servers && this.props.table.servers.length>0 ? "sticky-with-server" : "sticky")} ref={el => this.topBarAction = el} style={{zIndex: "1", height: this.state.topbarView === true }}>
          {/* <Button icon="pi-md-menu" className="alt-secondary-btn" onClick={(e) => this.menu.toggle(e)} style={{float: 'left', borderRadius: '3px', color:'white', background:'transparent'}} tooltip={trans('res.mgt.table_actions')} tooltipOptions={{position: 'top'}}/>
          <Menu className="customer-action-menu" style={{top: '35px', left: '0'}} ref={el=>this.menu=el} popup={true} model={[ 
            {label: trans('res.menu.languages'), icon: 'pi pi-globe', command: () => this.setState({ languageDialogVisible: true })},
            {disabled: !this.props.isOrderPage || !this.props.table, className: classNames({'p-hide': this.props.useFor!==USE_FOR_PURPOSE.res_dine_in }), label: trans('res.mgt.show_qr'), icon: 'pi-md-crop-free', command: () => this.QRCodeForm.show(this.props.table, `${applicationUrl}${this.props.table.code}/order`)},
            {disabled: this.state.btnCallWaiterDisable, className: classNames({'p-hide': !this.props.isOrderPage || !this.props.useFor===USE_FOR_PURPOSE.res_dine_in}), label: trans('res.menu.call_server'), icon: 'pi pi-bell', command: () => this.onClickCallWaiter()},
          ]}/> */}
          <div style={{float: 'left', borderRadius: '3px', color:'white', background:'transparent'}}>
            <Button className="customer-action-menu" label={" "} onClick={(e) => this.QRCodeForm.show(this.props.table, `${applicationUrl}${this.props.table.code}/order`)} tooltip={trans('res.mgt.show_qr')} tooltipOptions={{position: 'top'}} style={{ justifyContent: "center", alignItems: "center"}}><Icon icon="clarity:qr-code-line" width="25px"/></Button>
            <Button className="customer-action-menu" label={" "} onClick={() => this.setState({ languageDialogVisible: true })} tooltip={trans('res.menu.languages')} tooltipOptions={{position: 'top'}} style={{ justifyContent: "center", alignItems: "center"}}><Icon icon="clarity:language-line" width="25px"/></Button>
            <Button icon="pi pi-bell" className="customer-action-menu" label={trans('res.menu.call_server')} onClick={() => this.onClickCallWaiter()} tooltip={trans('res.menu.call_server')} tooltipOptions={{position: 'top'}} style={{ justifyContent: "center", alignItems: "center"}} />
          </div>
          {/* <button className="p-button p-button-secondary p-button-language p-margin-right-5" style={{float: 'left'}} title={trans('res.menu.change_lang')} onClick={() => this.setState({ languageDialogVisible: true })}>
            <img width={35} alt={this.state.language} src={cFlagImage} />
          </button> */}

          <div className="p-link topbar-menu-btn p-margin-right-20 customer" title={trans('res.menu.view_order')} >
            {/* <span className="basket-count" data-count={this.props.cartItemCount}>
              <i className="pi-md-room-service p-size-36"></i>
            </span> */}
            {resConfig.showResName && resConfig.resName && 
              <a className="restaurant-name">{resConfig.resName}</a>
            }
          </div>

          {this.props.isOrderPage && this.props.table &&
            <React.Fragment>
              {/* {this.props.useFor===USE_FOR_PURPOSE.res_dine_in &&
                <Button icon="pi-md-crop-free" tooltip={trans('res.mgt.show_qr')} tooltipOptions={{position: 'bottom'}} className="p-button-info p-float-left" style={{borderRadius: '3px'}} onClick={e => this.QRCodeForm.show(this.props.table, `${applicationUrl}${this.props.table.code}/order`)} />
              } */}

              {/* {this.props.blockOrdering?
                <Button label={trans('res.menu.view_order')} className="p-padding-0-5 p-float-right"  />
              : */}
                
              {/* } */}

              {/* {this.props.cartItemCount>0 && !this.props.blockOrdering && !this.props.isPrepaymentWhileEating &&
                <Button label={trans('res.menu.place_order')} className="p-padding-0-5 p-margin-right-5 p-float-right" onClick={this.props.onCartClick} />
              } */}
              {/* {(this.props.hasOrders && (this.props.table.requestStatus===TABLE_REQUEST_STATUS.order_confirmed || this.props.table.requestStatus===TABLE_REQUEST_STATUS.waiting_payment)) && !this.props.isPrepaymentWhileEating && 
                <React.Fragment>
                  
                  {this.props.table.requestStatus===TABLE_REQUEST_STATUS.order_confirmed?
                  <Button label={this.state.btnRequestCheckDisable?trans('res.menu.processing'):trans('res.menu.request_check')} disabled={this.state.btnRequestCheckDisable} className="p-button-warning p-padding-0-5 p-margin-right-5 p-float-right" onClick={() => this.onClickRequestCheck()} />
                  :
                  <Button label={trans('res.menu.make_payment')} className="p-padding-0-5 p-margin-right-5 p-float-right" onClick={() => this.props.handleMakePayment()} />
                  }
                </React.Fragment>
              } */}
              
              {this.state.topbarView === false ?
                <React.Fragment>
                    {this.props.useFor===USE_FOR_PURPOSE.res_dine_in ? 
                    <React.Fragment>
                      {(this.props.table.requestStatus===TABLE_REQUEST_STATUS.confirm_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.check_request
                        || this.props.table.requestStatus===TABLE_REQUEST_STATUS.making_payment
                        || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_cash_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_swipe_card_request) ?
                      <div style={{display: 'inline', position: 'relative'}}>
                        {/* <Message severity="warn" 
                          text={
                            this.props.table.requestStatus===TABLE_REQUEST_STATUS.confirm_request ? 
                              trans('res.menu.confirming_order') 
                            : this.props.table.requestStatus===TABLE_REQUEST_STATUS.check_request ? 
                                trans('res.menu.preparing_check') 
                              : this.props.table.requestStatus===TABLE_REQUEST_STATUS.making_payment ?
                                trans('res.menu.making_payment')
                                : trans('res.menu.verifying_payment')  
                          }
                        /> */}
                        {/* <Button className="p-float-left noti-buton" style={{borderRadius:"3%"}} icon="pi pi-envelope" tooltip={trans('res.mgt.show_notifications')} tooltipOptions={{ position: 'right' }} onClick={() => this.showNotis()}/> */}
                        <div style={{width:'256px', marginTop: '4px'}}>
                          {
                            this.state.notiButton ?
                            <DataScroller value={this.props.listNoti.length > 0 ? this.props.listNoti :emptyNotifications} itemTemplate={this.renderNotiDetail} rows={5} inline scrollHeight="150px" header={trans('res.mgt.list_notification')}/>: ''
                          }
                        </div>
                      </div>:
                      <div style={{display: 'inline', position: 'relative'}}>
                        {/* <Button className="p-float-left noti-buton" style={{borderRadius:"3%" } } icon="pi pi-envelope" tooltip={trans('res.mgt.show_notifications')} tooltipOptions={{ position: 'right' }} onClick={() => this.showNotis()}/> */}
                        <div style={{width:'256px', marginTop: '42px'}}>
                          {
                            this.state.notiButton ?
                            <DataScroller value={this.props.listNoti.length > 0 ? this.props.listNoti :emptyNotifications} itemTemplate={this.renderNotiDetail} rows={5} inline scrollHeight="150px" header={trans('res.mgt.list_notification')}/>: ''
                          }
                        </div>
                      </div>
                      }
                    </React.Fragment>
                  :
                    this.props.table.hasNewOrder &&
                      <Button label={trans('res.menu.view_my_orders')} className="p-padding-0-5 p-margin-right-10 p-float-right" onClick={() => this.props.goToOrderTracking()} />
                  }
                </React.Fragment>:

                <React.Fragment>
                  {this.props.useFor===USE_FOR_PURPOSE.res_dine_in ? 
                    <React.Fragment>
                      {(this.props.table.requestStatus===TABLE_REQUEST_STATUS.confirm_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.check_request
                        || this.props.table.requestStatus===TABLE_REQUEST_STATUS.making_payment
                        || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_cash_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_swipe_card_request) ?
                      <div className="p-col-12" style={{display: 'inline', position: 'relative'}}>  
                          {/* <Button className="p-float-left noti-buton" style={{borderRadius:"3%"}} icon="pi pi-envelope" tooltip={trans('res.mgt.show_notifications')} tooltipOptions={{ position: 'right' }} onClick={() => this.showNotis()}/> */}
                        {/* <div className="p-col-12" style={{marginTop:"25px"}}>
                          <Message severity="warn" 
                            text={
                              this.props.table.requestStatus===TABLE_REQUEST_STATUS.confirm_request ? 
                                trans('res.menu.confirming_order') 
                              : this.props.table.requestStatus===TABLE_REQUEST_STATUS.check_request ? 
                                  trans('res.menu.preparing_check') 
                                : this.props.table.requestStatus===TABLE_REQUEST_STATUS.making_payment ?
                                  trans('res.menu.making_payment')
                                  : trans('res.menu.verifying_payment')  
                            }
                          />
                        </div> */}
                        <div style={{width:'256px', marginTop: '4px'}}>
                          {
                            this.state.notiButton ?
                            <DataScroller value={this.props.listNoti.length > 0 ? this.props.listNoti :emptyNotifications} itemTemplate={this.renderNotiDetail} rows={5} inline scrollHeight="150px" header={trans('res.mgt.list_notification')}/>: ''
                          }
                        </div>
                      </div>:
                      <div style={{display: 'inline', position: 'relative'}}>
                        {/* <Button className="p-float-left noti-buton" style={{borderRadius:"3%" } } icon="pi pi-envelope" tooltip={trans('res.mgt.show_notifications')} tooltipOptions={{ position: 'right' }} onClick={() => this.showNotis()}/> */}
                        <div style={{width:'256px', marginTop: '42px'}}>
                          {
                            this.state.notiButton ?
                            <DataScroller value={this.props.listNoti.length > 0 ? this.props.listNoti :emptyNotifications} itemTemplate={this.renderNotiDetail} rows={5} inline scrollHeight="150px" header={trans('res.mgt.list_notification')}/>: ''
                          }
                        </div>
                      </div>
                      }
                    </React.Fragment>
                  :
                    this.props.table.hasNewOrder &&
                      <Button label={trans('res.menu.view_my_orders')} className="p-padding-0-5 p-margin-right-10 p-float-right" onClick={() => this.props.goToOrderTracking()} />
                  }
                </React.Fragment>
                
              }
            </React.Fragment>
          }

          {/* {this.props.isOrderPage && this.props.useFor===USE_FOR_PURPOSE.res_dine_in  &&
            <Button icon="pi pi-bell" className="p-button-warning p-float-left call-server-btn" style={{borderRadius: '3px'}} disabled={this.state.btnCallWaiterDisable} onClick={() => this.onClickCallWaiter()} />
          } */}

          {!this.props.isOrderPage && this.props.useFor===USE_FOR_PURPOSE.res_take_out &&
            <Button label={trans('res.menu.back_to_order_page')} icon="pi-md-arrow-back" className="p-button p-margin-right-10 p-float-right" onClick={() => this.props.goToTakeAwayOrder()} />
          }
        </div>
        {/* {this.props.isOrderPage && this.props.table && this.props.table.servers && this.props.table.servers.length>0 &&
        // <div className="topbar-action sticky-server" ref={el => this.topBarServer = el} style={{height: 'auto', padding: '0px 5px 10px 5px', top: `${this.topBarAction?this.topBarAction.offsetHeight:58}px`}}>
        
        <div className="topbar-action sticky-server" ref={el => this.topBarServer = el} style={{padding: '0px 5px 10px 5px', top: this.state.topbarView === true ? '90px' : '57px'}}>
          <strong>{trans('res.menu.server')}: 
            {this.props.table.servers.map((server, idx) => {
              return <span className="p-margin-left-5">{server.name + (idx < (this.props.table.servers.length-1) ? ', ' : '')}</span>
            })}
          </strong>
        </div>
        } */}

        {/* <div className="topbar-header p-c" ref={el => this.resBanner = el} style={{backgroundColor: resConfig.resTopbarBackgroundColor, color: resConfig.resTopbarTextColor, paddingTop: `${this.topBarAction&&this.topBarServer?this.topBarAction.offsetHeight+this.topBarServer.offsetHeight+10:80}px` }}>
          {resConfig.resLogo &&
            <img className="topbar-header-logo" height={resConfig.resLogoHeight?resConfig.resLogoHeight:65} alt="LOGO" src={`/restaurant/assets/restaurant_logo.png`}/>}
          {resConfig.showResName && resConfig.resName && 
            <h1 className="topbar-header-title">{resConfig.resName}</h1>}
          {resConfig.showBrandingName && resConfig.brandingName &&
            <h2 className="topbar-header-branding">{resConfig.brandingName}</h2>}
        </div> */}
        {this.props.isOrderPage && this.props.table && this.props.totalQty>0 &&
          <div className="p-col-12 bottombar">
            <div className="p-grid">
              <div className="left p-margin-top-5">
                <h2 className="item-quantity-text">
                  {this.props.totalQty} {this.props.totalQty>1?trans('res.menu.items'): trans('res.menu.item')} 
                </h2>
                </div>
              <div className="right">
                {/* {this.props.blockOrdering?
                  <Button label={trans('res.menu.view_order')} className="p-padding-0-5 p-float-right" onClick={this.props.onCartClick} />
                : */}
                  <button className="p-link" onClick={this.props.onCartClick}
                    title={trans('res.menu.view_order')}>
                    {/* <span className="basket-count" data-count={this.props.cartItemCount}> */}
                      {/* <i className="pi-md-room-service p-size-36"></i> */}
                      <h2 className="order-menu-btn">{trans('res.menu.order')}</h2>
                    {/* </span> */}
                  </button>
                {/* } */}
              </div>
            </div>
          </div>
      } 
      </div>
    );
  }
}

export default withNamespaces("message")(CustomerOrderTopbar);
