import React, { Component } from "react";
import moment from "moment";
import classNames from 'classnames';
import { withNamespaces } from "react-i18next";
import './MenuOrdering.scss';
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import Tippy from '@tippy.js/react';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/perspective.css';
import { QRCodeForm } from "../core/components/QRCodeForm";
import { moneyFormat } from "../core/service/CommonService";
import { AddFoodItem } from "./AddFoodItem";
import { USE_FOR_PURPOSE, PRODUCT_OPTION_TYPE, CHANNEL_TYPE, TABLE_REQUEST_STATUS } from "../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Message} from 'primereact/message';
import {Card} from 'primereact/card';
import { getAPProducts, getDepartments } from "../service/ExternalProductsService";

export class MenuOrdering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestStatus: this.props.requestStatus,
      activeMenu: localStorage.getItem('selectedMenu') ? parseInt(localStorage.getItem('selectedMenu')) : null,
      data: {
        menus: [],
        categories: [],
        menuDiscountBars: [],
        productDiscountBars: []
      },
      frmAddFoodVisible: false,
      frmOrderItem: {},
      selectedFood: null,
      foodVariantOptions: [],
      errors: {},
      frmCartVisible: false,
      selectedCategory: [],
      filter: {
        dishName: ''
      }
    };
  }

  popularMenuData = (data) => {
    data.categories && data.categories.forEach(category => {
      this.setState({
        [`cate_${category.id}`]: true
      })  
    })

    let productReq = {
      app: "fep",
      branchId: 0,
      branchIds: [],
      isActive: true,
      isShow: true,
      departmentIds: [1],
      searchValue: ""
    };
    let departmentReq = {
      active: true,
      externalRef: false
    }
    let showStoreProducts = localStorage.getItem('showStoreProducts');
    if(showStoreProducts === "false"){
      let menuDiscountBars = [];
      let productDiscountBars = [];
      if(data.validPromotions && data.validPromotions.length>0){
        data.validPromotions.map(p => {
          if((this.props.useFor === USE_FOR_PURPOSE.res_dine_in && p.channels.includes(CHANNEL_TYPE.restaurant)) || (this.props.useFor === USE_FOR_PURPOSE.res_take_out && p.channels.includes(CHANNEL_TYPE.restaurant_2))){
            if(p.rules.length>0){
              menuDiscountBars = p.rules.filter(r => r.showDiscountBarOnMenu);
              productDiscountBars = p.rules.filter(r => r.showDiscountBarOnProduct);
            }
          }
        });
      }
      this.setState({
        data: {
          menus: data.menus,
          categories: data.categories,
          menuDiscountBars: menuDiscountBars,
          productDiscountBars: productDiscountBars
        },
      });
    }else{
      getDepartments(departmentReq).then(res => {
        let depIds = [];
        if(res){
          res.map(d => {
            depIds.push(d.id)
          })
          productReq.departmentIds = depIds;
          getAPProducts(productReq).then(res2 => {
            if(res2){
              let apMenus ={
                id: "ap",
                tables: [this.props.table.id],
                name: "AP Products",
                schedule: {
                  days: ['mon','tue','wed','thu','fri','sat','sun'],
                  times: [{
                    s:"",
                    e:""
                  }]
                },
                enabled: true,
                useFor: ["res_delivery_now", "res_time_order", "res_dine_in", "res_take_out"],
              }
              let newData = data;
              if(showStoreProducts === "true" && res && res.length > 0 && res2 && res2.length > 0){
                newData.menus.push(apMenus);
                res.map(p => {
                  let apDepartment = {
                    enabled: p.active,
                    id: "apCate-" + p.id,
                    desc: p.shortDescription,
                    foodProducts: [],
                    name: p.name,
                    trans: {
                      descs: {},
                      names: {}
                    }
                  }
                  res2.map(d => {
                    let img = null;
                    if(d.departmentId === p.id){
                      if(d.teaserImage && d.teaserImage.type && d.teaserImage && d.teaserImage.url){
                        img= {
                          fileName: d.teaserImage.type,
                          url: d.teaserImage.url
                        }
                      }
                      let apProduct = {
                        active : d.active,
                        name: d.name,
                        categoryIds: [apDepartment.id],
                        descs: d.description,
                        id: "apProd-" + d.id,
                        addons: [],
                        images: img ? [img] : [],
                        menuIds: "ap",
                        names:{},
                        options: [],
                        price01: 0,
                        price02: 0,
                        pricedByVariant: false,
                        properties:{
                          calories: 0,
                          showCalories: false,
                        },
                        tagAllergens: [],
                        tagIngredients: [],
                        useFor: ["res_dine_in", "res_take_out", "res_time_order", "res_delivery_now"],
                        variantSelectionType: 1,
                        variants: [],
                      }
                      apDepartment.foodProducts.push(apProduct);
                    }
                  })
                  newData.categories.push(apDepartment);
                })
              }
              
              let menuDiscountBars = [];
              let productDiscountBars = [];
              if(data.validPromotions && data.validPromotions.length>0){
                data.validPromotions.map(p => {
                  if((this.props.useFor === USE_FOR_PURPOSE.res_dine_in && p.channels.includes(CHANNEL_TYPE.restaurant)) || (this.props.useFor === USE_FOR_PURPOSE.res_take_out && p.channels.includes(CHANNEL_TYPE.restaurant_2))){
                    if(p.rules.length>0){
                      menuDiscountBars = p.rules.filter(r => r.showDiscountBarOnMenu);
                      productDiscountBars = p.rules.filter(r => r.showDiscountBarOnProduct);
                    }
                  }
                });
              }
              this.setState({
                data: {
                  menus: newData.menus,
                  categories: data.categories,
                  menuDiscountBars: menuDiscountBars,
                  productDiscountBars: productDiscountBars
                }
              });
            }
          })
        }
      })
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    let currentHideNav = (window.innerWidth <= 400);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({topbarView: currentHideNav});
    }
  }

  //Customer Menu Function
  onMenuClick = (menu) => {
    this.setState({ activeMenu: menu.id })
    localStorage.setItem('selectedMenu', menu.id)
  };

  menuTemplate(item, activeMenu, selectedLocale, defaultLocale) {
    return (
      <div key={"menu_" + item.id} className="c-menu-item-wrapper">
        <button key={item.id} className={classNames('c-menu-item', { 'c-menu-item-active': activeMenu === item.id })} onClick={() => this.onMenuClick(item)}>{item.trans?item.trans.names[selectedLocale]?item.trans.names[selectedLocale]:item.trans.names[defaultLocale]?item.trans.names[defaultLocale]:item.name:item.name}</button>
      </div>
    );
  }

  onFoodClick = (item, category, blockOrdering) => {
    if(item.menuIds === 'ap'){
      window.open("https://ap-test-event.fromlabs.com/bookings", "Popup");
    }else{
      (!blockOrdering && item.stockAvailable)&&this.frmAddFoodItem.popularFrmOrderItem(item, category);
    }
  };

  foodTemplate(item, trans, category, blockOrdering, useFor, disableOrder) {
    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');
    const resConfig = this.props.config;
    return (
      <React.Fragment key={category.id + "-" + item.id} >
        <div key={item.id} className={classNames("c-food-item", { "c-food-item-cursor": (!blockOrdering && item.stockAvailable)})} onClick={() => this.onFoodClick(item, category, blockOrdering, disableOrder)}>
          <div className="p-grid">
            {item.images && item.images.length>0 &&
            <div className={item.menuIds === 'ap' ? "p-col-4 ap-dish-image" :"p-col-4 menu-dish-image"}>
              <div className="dish-image-square">
                <LazyLoadImage src={item.images[0].url} alt={item.images[0].fileName}/>
              </div>
              {item.menuIds === 'ap' &&
                <div className="p-c">
                  <Button label={trans('res.mgt.view_order_short')} className="btn-view-ap"/>
                </div>
              }
            </div>
            }
            
            <div className={item.images && item.images.length>0?"p-col-8":"p-col-12"}>
            {/* <div className="p-col-9"> */}
              <div className="p-grid">
                <div className="p-col-12 item-price">
                  <div className="title-line">
                    <div className="title">
                      <h2>{item.names[selectedLocale] ? item.names[selectedLocale] : item.names[defaultLocale] ? item.names[defaultLocale] : item.name}</h2>
                      {!item.pricedByVariant && item.menuIds !== 'ap' &&
                        <span className="price-box">{moneyFormat(useFor===USE_FOR_PURPOSE.res_dine_in?item.price01:item.price02)}</span>
                      }
                    </div>
                  </div>
                  
                  {item.pricedByVariant && item.variants.length>0 &&
                  <div className="variant-wrapper p-margin-top-5">
                    {item.variants.map(v => {
                      return (
                        <React.Fragment key={category.id + "_" + item.id + "_" + v.id}>
                          <div className="variant title">
                            <h2>{this.optionValueIdsTranslate(item, v.optionValueIds)}</h2>
                            {item.menuIds !== 'ap' &&
                              <div className="price-box">{moneyFormat(useFor===USE_FOR_PURPOSE.res_dine_in?v.extraPrice:v.extraPrice02)}</div>
                            }
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                  }
                </div>
              </div>

              {/* <div className="p-grid">
                <div className="p-col-12">
                  {item.discountDetails && item.discountDetails.length>0 && item.discountDetails.map(discount => {
                    if(this.state.data.promotionRules && this.state.data.promotionRules[discount.promotionRuleId] && discount.details && discount.details.length>0){
                      return (
                        discount.details.map(detail => {
                          let detailTrans = detail.replace('$', getCurrencySymbol()) 
                          return (<p className={classNames('c-menu-item-discount')} >{detailTrans}</p>);
                        })
                      )
                    }
                  })}
                </div>
              </div> */}

              <div className="p-grid">
                <div className="p-col-12">
                  {item.properties && item.properties.showCalories &&
                    <div className="p-margin-bottom-5">{trans('res.mgt.calories')}: {item.properties.calories}</div>
                  }
                  {/* <div className="c-food-item-desc">{item.descs[selectedLocale] ? item.descs[selectedLocale] : item.descs[defaultLocale]}</div> */}
                  {(item.tagAllergens.length>0 || item.tagIngredients.length>0) &&
                  <div className="p-margin-top-10">
                    {item.tagAllergens.map(tag => {
                      return (
                        <Tippy
                          content={tag.trans.descs && tag.trans.descs[selectedLocale] ? tag.trans.descs[selectedLocale] : tag.trans.descs[defaultLocale]}
                          animation="fade"
                          placement="top"
                          trigger="mouseenter focus"
                          interactive={true}
                          arrow={true}
                          maxWidth='none'
                          duration={[350, 200]}
                        >
                          <span className="item-tag item-tag-red" style={{backgroundColor: resConfig.resTagAllergenColor}}>{tag && tag.name ? tag.name : ''}</span>
                        </Tippy>
                      );
                    })}
                    {item.tagIngredients.map(tag => {
                      return (
                        <Tippy
                          content={tag.trans && tag.trans.descs && tag.trans.descs[selectedLocale] ? tag.trans.descs[selectedLocale] : tag.trans.descs[defaultLocale]}
                          animation="fade"
                          placement="top"
                          trigger="mouseenter focus"
                          interactive={true}
                          arrow={true}
                          maxWidth='none'
                          duration={[350, 200]}>
                          <span className="item-tag item-tag-blue" style={{backgroundColor: resConfig.resTagIngredientColor}}>{tag && tag.name ? tag.name : ''}</span>
                        </Tippy>
                      );
                    })}
                  </div>
                  }
                  {item.menuIds === 'ap'?
                    <div className="p-col-12">
                      <div className="p-grid">
                        <div className="p-col-12 p-l">
                          <p>{item.descs}</p>
                        </div>
                        
                      </div>
                    </div>:
                    <Button label={disableOrder?trans('res.menu.view_food_detail'):trans('res.menu.add_food_to_order')} className="btn-add-order" disabled={!item.stockAvailable || blockOrdering}/>
                  }
                  {(!item.images || item.images.length<=0) && item.menuIds === 'ap' &&
                    <div className="p-r">
                      <Button label={trans('res.mgt.view_order_short')} className="btn-add-order"/>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div key={"item_clear_" + item.id} className="c-food-empty-col"></div>
      </React.Fragment>
    );
  }

  loadMenus = (items) => {
    let now = moment();
    let day = now.format("ddd").toLowerCase();
    let time =
      Number.parseInt(now.format("m")) + Number.parseInt(now.format("H")) * 60;
    return  this.props.useFor && this.props.useFor === USE_FOR_PURPOSE.res_time_order ?
      items.filter(
        (i) => (!i.tables || i.tables.length===0 || (i.tables.length>0 && this.props.table && i.tables.includes(this.props.table.id)) && i.enabled)
      )
    :
      items.filter(
        (i) => (!i.tables || i.tables.length===0 || (i.tables.length>0 && this.props.table && i.tables.includes(this.props.table.id))) &&
          i.useFor.includes(this.props.useFor) && i.enabled && (!i.schedule.days || (i.schedule.days && i.schedule.days.includes(day))) &&
          this.compareTimes(i.schedule.times, time)
      );
  };

  compareTimes = (items, time) => {
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        let item = items[i];

        let startTime = item.s ? item.s : "00:00";
        let endTime = item.e ? item.e : "23:59";
        let startParts = startTime.split(":");
        let start =
          Number.parseInt(startParts[1]) + Number.parseInt(startParts[0]) * 60;
        let endParts = endTime.split(":");
        let end =
          Number.parseInt(endParts[1]) + Number.parseInt(endParts[0]) * 60;

        if (time >= start && time <= end) return true;
      }
      return false;
    } else {
      return true;
    }
  };

  loadFoods = (items, menu) => {
    const foodList = this.state.dishIds;
    return foodList && foodList.length > 0 ? 
      menu === null ? items.filter((i) => i.useFor.includes(this.props.useFor) && foodList.includes(i.id)) : items.filter((i) => i.menuIds.includes(menu) && i.useFor.includes(this.props.useFor) && foodList.includes(i.id))
      :
      menu === null ? items.filter((i) => i.useFor.includes(this.props.useFor)) : items.filter((i) => i.menuIds.includes(menu) && i.useFor.includes(this.props.useFor))
  };

  optionValueIdsTranslate = (item, optValueIds) => {
    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');

    const variantOpts = item.options.filter(o => o.type===PRODUCT_OPTION_TYPE.variant);

    let optValueNames = [];

    optValueIds.map((oValId, oValIdx) => {
      const optValue = variantOpts.length > 0  ? variantOpts[oValIdx].values.find(v => v.id===oValId) : null;

      optValue && optValueNames.push(optValue.trans.names[selectedLocale] ? optValue.trans.names[selectedLocale] : optValue.trans.names[defaultLocale] ? optValue.trans.names[defaultLocale] : optValue.name);
    });

    return optValueNames.join(', ');
  }

  searchDish = () => {
    const activeMenu = localStorage.getItem('selectedMenu') ? parseInt(localStorage.getItem('selectedMenu')) : null;
    let listDishId = []
    this.state.data.categories.map(item => {
      item.foodProducts.map(p => {
        if(p.name.toLowerCase().includes(this.state.filter.dishName.toLowerCase())){
          listDishId.push(p.id)
        }
      })
    })
    this.setState({
      dishIds: listDishId
    },() => {
      this.state.data.categories.map((category) => {
          this.loadFoods(category.foodProducts, activeMenu ? activeMenu : this.state.activeMenu);}
      )
    })
  }

  onTogglePanel = (state) => {
    this.state.data.categories && this.state.data.categories.map((category) => {
      this.setState({
        [`cate_${category.id}`]: state
      })  
    })
  }

  onChangeTimeOrderDate = () =>{
    this.props.changeDateTime();
  }

  render() {
    const defaultLocale = localStorage.getItem('defaultLocale');
    const selectedLocale = localStorage.getItem('selectedLocale');
    const trans = this.props.trans;
    const blockOrdering = this.props.blockOrdering;
    const activeMenu = localStorage.getItem('selectedMenu') ? parseInt(localStorage.getItem('selectedMenu')) : null;
    const resConfig = this.props.config;
    let addHeight = 0;
    
    if(resConfig.showResName && resConfig.resName)
      addHeight= addHeight + 15
    if(resConfig.showBrandingName && resConfig.brandingName)
      addHeight= addHeight + 15
    
    return (
      <div className="menu-wrapper">
        {/* <div className="topbar-header p-c" ref={el => this.resBanner = el} style={{backgroundColor: resConfig.resTopbarBackgroundColor, color: resConfig.resTopbarTextColor,  paddingBottom: "10px", paddingTop: this.props.topbarHeight + addHeight}}>
          {resConfig.resLogo &&
            <img className="topbar-header-logo" height={resConfig.resLogoHeight?resConfig.resLogoHeight:65} alt="LOGO" src={`/restaurant/assets/restaurant_logo.png`}/>}
          {resConfig.showResName && resConfig.resName && 
            <h1 className="topbar-header-title">{resConfig.resName}</h1>}
          {resConfig.showBrandingName && resConfig.brandingName &&
            <h2 className="topbar-header-branding">{resConfig.brandingName}</h2>}
        </div> */}
        <AddFoodItem ref={(el) => this.frmAddFoodItem = el} trans={trans}
          config={this.props.config}
          orderId={this.props.orderId} 
          currency={this.props.currency} 
          useFor={this.props.useFor}
          disableOrder={this.props.disableOrder}
          discountBars={this.state.data.productDiscountBars}
          updateCartOrder={(e) => this.props.updateCartOrder(e)}
        />

        <QRCodeForm ref={el => this.QRCodeForm=el} trans={trans} hideCode={true} hideUrl={true}/>

        {(() => {
          let menus = this.loadMenus(this.state.data.menus)
          let activeMenu = this.state.activeMenu
          if (menus.length > 0) {
            let needle = 0
            if (activeMenu) {
              needle = menus.findIndex((mi) => mi.id === activeMenu)
              if (needle === -1) {
                needle = 0
              }
            }
            activeMenu = menus[needle].id
            localStorage.setItem('selectedMenu', menus[needle].id)
          }
          
          return (
            <React.Fragment>
              <div className="p-col-12" >
                <div className="p-grid form-group p-margin-top-10 c-menu">
                  <div className="p-col-12 p-c">
                    <div className="p-grid">
                        {this.props.useFor === USE_FOR_PURPOSE.res_time_order &&
                          <div className="p-col-12">
                            <Card style={{marginTop: "65px"}}>
                              <div className="p-grid">
                                <div className="p-col-12 p-md-6 p-l">
                                  <label>{trans('res.menu.pick_up_at')}: <strong>{this.props.orderTime}</strong></label>
                                </div>
                                <div className="p-col-12 p-md-6 p-r">
                                  <Button label={trans('res.mgt.change')} onClick={() => this.onChangeTimeOrderDate()} />
                                </div>
                              </div>
                            </Card>
                          </div>
                        }

                      {(this.props.table.requestStatus===TABLE_REQUEST_STATUS.confirm_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.check_request
                        || this.props.table.requestStatus===TABLE_REQUEST_STATUS.making_payment
                        || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_cash_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_swipe_card_request) &&
                        <div className="p-col-12">
                          <Message severity="warn" className="order-status-warn"
                            text={
                              this.props.table.requestStatus===TABLE_REQUEST_STATUS.confirm_request ? 
                                trans('res.menu.confirming_order') 
                              : this.props.table.requestStatus===TABLE_REQUEST_STATUS.check_request ? 
                                  trans('res.menu.preparing_check') 
                              : this.props.table.requestStatus===TABLE_REQUEST_STATUS.making_payment ?
                                  trans('res.menu.making_payment')
                              : trans('res.menu.verifying_payment')  
                            }
                          />
                        </div>
                        
                      }
                      <div className="p-col-12">
                        <InputText placeholder={'Search Food Name'} 
                          type="search"
                          className={classNames("searchbox", {"searchbox-margin" : !(this.props.table.requestStatus===TABLE_REQUEST_STATUS.confirm_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.check_request
                          || this.props.table.requestStatus===TABLE_REQUEST_STATUS.making_payment
                          || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_cash_request || this.props.table.requestStatus===TABLE_REQUEST_STATUS.pay_by_swipe_card_request) })} 
                          value={this.state.filter.dishName}
                          onChange={(e) => this.setState({filter: {...this.state.filter, dishName: e.target.value}})}>
                        </InputText> 
                        <Button icon="pi-md-search" className="search-button" onClick={() => this.searchDish()}></Button>
                      </div>
                    </div>
                    
                    
                  </div>
                  
                  {menus.length>0 ?
                    <div className="p-col-12" style={{ position: "relative" }}>
                      <div className="c-menu-wrapper">
                        {menus.map((item) => this.menuTemplate(item, activeMenu, selectedLocale, defaultLocale))}
                      </div>
                    </div>
                    :
                    <div className="p-col-12 p-c"><Message severity="warn" text={trans('res.menu.empty_menu_warning')} /></div>
                  }
                </div>
              </div>
              <div className="filter-menu-customer p-margin-bottom-10">
                <Button className="button-expand" label="Expand All" onClick={() => this.onTogglePanel(false)}></Button>
                <Button className="button-collapse" label="Collapse All" onClick={() => this.onTogglePanel(true)}></Button>
              </div>
            </React.Fragment>
          );
        })()}

        {this.state.data.menuDiscountBars && this.state.data.menuDiscountBars.length>0 && this.state.data.menuDiscountBars.map((r, idx) => {
          const barContent = r.trans?r.trans.discountBarContent[selectedLocale]?r.trans.discountBarContent[selectedLocale]:r.trans.discountBarContent[defaultLocale]:'';
          const image = r.image? r.image:null;
          if(r.showDiscountBarFlying && image){
            return (
              <div key={idx} className="p-menu-discount-bar" style={{textAlign:"center", backgroundColor: r.discountBarBackgroundColor?'#'+r.discountBarBackgroundColor:'#ffc107', color: r.discountBarTextColor?'#'+r.discountBarTextColor:'#fff'}}>
                <LazyLoadImage src={r.url} alt={r.fileName}/>
              </div>
            )
          } else if(barContent){
            return (
              <div key={idx} className="p-menu-discount-bar" style={{textAlign:"center", backgroundColor: r.discountBarBackgroundColor?'#'+r.discountBarBackgroundColor:'#ffc107', color: r.discountBarTextColor?'#'+r.discountBarTextColor:'#fff'}}>
                <div>{barContent}</div>
              </div>
            )
          }
        })}

        {/* <div className="p-grid">
            {Object.keys(this.state.data.promotionRules).map(id => {
              let rule = this.state.data.promotionRules[id];
              return(
                rule.discountDetails.map((detail) => {
                  let detailTrans = detail.replace('$', getCurrencySymbol())
                  return(
                  <div class="p-col-12" style={{textAlign:"center"}}>
                    <p className={classNames('c-menu-cart-discount')} >{detailTrans}</p>
                  </div>
              )}))
            })}
        </div> */}

        {
          this.state.filteredCategory && this.state.filteredCategory.length > 0 ?
          <div className="p-col-12 category-wrapper">
            {this.state.data && this.state.data.menus && this.loadMenus(this.state.data.menus).length>0 && 
              this.state.filteredCategory.map((category) => {
                let items = this.loadFoods(category.foodProducts, activeMenu ? activeMenu : this.state.activeMenu);
                if (items.length > 0) {
                  return (
                    <React.Fragment>
                      <Panel key={"cate_" + category.id} header={category.trans.names[selectedLocale] ? category.trans.names[selectedLocale] : category.trans.names[defaultLocale] ? category.trans.names[defaultLocale] : category.name} className="customer p-margin-top-0" toggleable={true} headerClassName="p-accordion-header-customer">
                        {category.desc &&
                          <p className="p-margin-top-0 p-margin-bottom-10">{category.trans.descs[selectedLocale] ? category.trans.descs[selectedLocale] : category.trans.descs[defaultLocale] ? category.trans.descs[defaultLocale] : category.desc}</p>
                        }
                        <div className="c-food-wrapper p-margin-bottom-20">
                          {items.map((item) => this.foodTemplate(item, trans, category, blockOrdering, this.props.useFor, this.props.disableOrder))}
                        </div>
                      </Panel>
                      <br></br>
                    </React.Fragment>
                    
                  );
                }
              })
            }
          </div>
          :
          <div className="p-col-12 category-wrapper">
            {this.state.data && this.state.data.menus && this.loadMenus(this.state.data.menus).length>0 && 
              this.state.data.categories && this.state.data.categories.map((category, cIdx) => {
                let items = this.loadFoods(category.foodProducts, activeMenu ? activeMenu : this.state.activeMenu);
                if (items.length > 0) {
                  return (
                    <React.Fragment key={cIdx}>
                      <Panel 
                        onToggle={(e) => e.value!==null&&this.setState({ [`cate_${category.id}`]: e.value })}
                        collapsed={this.state[`cate_${category.id}`]?this.state[`cate_${category.id}`]:false}
                        key={"cate_" + category.id} 
                        header={category.trans.names[selectedLocale] ? category.trans.names[selectedLocale] : category.trans.names[defaultLocale] ? category.trans.names[defaultLocale] : category.name} 
                        className="customer p-margin-top-0" 
                        toggleable={true} 
                        headerClassName="p-accordion-header-customer"
                      >
                        {category.desc &&
                          <p className="p-margin-top-0 p-margin-bottom-10">{category.trans.descs[selectedLocale] ? category.trans.descs[selectedLocale] : category.trans.descs[defaultLocale] ? category.trans.descs[defaultLocale] : category.desc}</p>
                        }
                        <div className="c-food-wrapper p-margin-bottom-20">
                          {items.map((item) => this.foodTemplate(item, trans, category, blockOrdering, this.props.useFor, this.props.disableOrder))}
                        </div>
                      </Panel>
                      <br></br>
                    </React.Fragment>
                    
                  );
                }
              })
            }
          </div>
        }
        <div className="space"></div>
      </div>
    );
  }
}

export default withNamespaces("message")(MenuOrdering);
