import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import classNames from "classnames";
import { createBrowserHistory } from "history";
import "../ripple.js";
import "../CustomerOrder.scss";
import "./StandAloneMenu.scss";
import "../order/MenuOrdering.scss";
import "../common.css";
import { logOut } from "../core/security/auth.jsx";
import LoadingOverlay from "../core/components/LoadingOverlay.js";
import { Button } from "primereact/button";
import { showloading, stoploading } from "../core/service/LoadingService";
import { loadPublicConfiguration } from "../service/ConfigurationService";
import { moneyFormat } from "../core/service/CommonService.js";
import { Dialog } from "primereact/dialog";
import { QRCodeForm } from "../core/components/QRCodeForm.js";
import { getMenuData } from "../service/MenuService.js";
import Tippy from '@tippy.js/react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { USE_FOR_PURPOSE, PRODUCT_OPTION_TYPE } from "../constants.js";
import { Panel } from "primereact/panel";

class StandAloneMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resConfig: {},
            topbarHeight: 65,
            activeTopbarItem: null,
            layoutMode: "static",
            mobileMenuActive: null,
            topbarMenuActive: null,
            menuActive: false,
            wsNotifications: [],
            userNotifications: [],
            refreshNotification: false,
            table: {
                name: null
            },
            tableSize: 0,
            dishCategories: [],
            draftOrder: {
                items: []
            },
            draftGroupOrderItems: [],
            waitingConfirmOrders: [],
            waitingConfirmGroupOrderItems: [],
            orders: [],
            groupOrderItems: [],
            tipOrders: [],
            language: 'en-US',
            frmCartVisible: false,
            cFlagImage: '',
            paymentOption: '',
            paymentMethod: '',
            orderSummary: {},
            activePayment: {},
            tipAmount: '',
            stripeToken: '',
            confirmOption: {},
            data: {
                menus: [],
                categories: []
            },
            activeMenu: localStorage.getItem('selectedMenu') ? parseInt(localStorage.getItem('selectedMenu')) : null
        };

        this.notificationCount = 0;

        this._history = createBrowserHistory();
        this.unlisten = this._history.listen(() => { });
    }

    async componentDidMount() {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });

        const config = await loadPublicConfiguration();
        const { i18n } = this.props;
        let locale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');

        i18n.changeLanguage(locale);
        this.setState({ language: locale });

        const resConfig = config.data;
        this.setState({
            resConfig: resConfig
        });
        this.getTopbarHeight();
        this.loadAllData();
    }

    handleLogout = (e, redirectTo = "/login") => {
        e.preventDefault();
        logOut();
        this._history.push(redirectTo);
    };

    changeLanguage = (e) => {
        this.setState({ language: e });
        const { i18n } = this.props;
        i18n.changeLanguage(e);
        localStorage.setItem('selectedLocale', e);
    };

    componentWillUnmount() {
        this.unlisten();
        // notificationEvent.remove('USER_NOTIFICATION', this.onUserNotificationReceived);
    }

    getFlagPath = (locale) => {
        if (locale === null) return;
        let style = this.props.flagStyle ? this.props.flagStyle : 'rectangle';
        return require('../core/components/flags/flag-' + style + '-' + locale + '.png');
    }

    getTopbarHeight = () => {
        this.setState({
            topbarHeight: this.topBar.clientHeight + 5
        });
    }

    getLangName = (key) => {
        switch (key) {
            case 'en-US':
                return 'English';
            case 'en-UK':
                return 'English';
            case 'fr-FR':
                return 'Français';
            case 'fi':
                return 'Suomi';
            case 'sv-SE':
                return 'Svenska';
            case 'de':
                return 'Deutsch';
            case 'vi':
                return 'Tiếng Việt';
            case 'es':
                return 'Español';
            case 'in':
                return 'Bahasa';
            case 'pt-PT':
                return 'Português';
            case 'pt-BR':
                return 'Português';
            case 'zh-CN':
                return '中文';
            case 'ja':
                return '日本語';
            case 'ko':
                return '한국어';
            case 'nl':
                return 'Dutch';
            case 'no':
                return 'Norwegian';
            case 'tr':
                return 'Turkish';
            case 'zh-TW':
                return 'Taiwanese';
            case 'da':
                return 'Danish';
            case 'ru':
                return 'русский язык';
            default:
                return '';
        }
    }

    loadAllData = () => {
        showloading();
        getMenuData(null, false).then(res => {
            this.setState({
                data: {
                    menus: res.menus,
                    categories: res.categories
                }
            }, () => {
                this.setState({ dishCategories: res.categories });
                stoploading();
            })
        })
    }

    onMenuClick = (menu) => {
        this.setState({ activeMenu: menu.id });
        localStorage.setItem('selectedMenu', menu.id)
    };

    menuTemplate(item, activeMenu, selectedLocale, defaultLocale) {
        return (
            <div key={"menu_" + item.id} className="c-menu-item-wrapper">
                <button key={item.id} className={classNames('c-menu-item', { 'c-menu-item-active': activeMenu === item.id })} onClick={() => this.onMenuClick(item)}>{item.trans ? item.trans.names[selectedLocale] ? item.trans.names[selectedLocale] : item.trans.names[defaultLocale] ? item.trans.names[defaultLocale] : item.name : item.name}</button>
            </div>
        );
    }

    foodTemplate(item, trans, category, useFor) {
        const defaultLocale = localStorage.getItem('defaultLocale');
        const selectedLocale = localStorage.getItem('selectedLocale');
        const currency = localStorage.getItem('currencySymbol');
        const resConfig = localStorage.getItem('resConfig') ? JSON.parse(localStorage.getItem('resConfig')) : {};;

        return (
            <React.Fragment key={category.id + "-" + item.id} >
                <div key={item.id} className="c-food-item">
                    <div className="p-grid">
                        {item.images && item.images.length > 0 &&
                            <div className="p-col-3 menu-dish-image">
                                <div className="dish-image-square">
                                    <LazyLoadImage src={item.images[0].url} alt={item.images[0].fileName} />
                                </div>
                            </div>
                        }

                        <div className={item.images && item.images.length > 0 ? "p-col-9" : "p-col-12"}>
                            <div className="p-grid">
                                <div className="p-col-12 item-price">
                                    <div className="title-line">
                                        <div className="title-standalone">
                                            <h2>{item.names[selectedLocale] ? item.names[selectedLocale] : item.names[defaultLocale] ? item.names[defaultLocale] : item.name}</h2>
                                            {!item.pricedByVariant &&
                                                <span className="price-box">{moneyFormat(useFor === USE_FOR_PURPOSE.res_dine_in ? item.price01 : item.price02)}</span>
                                            }
                                        </div>
                                    </div>

                                    {item.pricedByVariant && item.variants.length > 0 &&
                                        <div className="variant-wrapper p-margin-top-5">
                                            {item.variants.map(v => {
                                                return (
                                                    <React.Fragment key={category.id + "_" + item.id + "_" + v.id}>
                                                        <div className="variant title">
                                                            <h2>{this.optionValueIdsTranslate(item, v.optionValueIds)}</h2>
                                                            <span className="price-box">{moneyFormat(useFor === USE_FOR_PURPOSE.res_dine_in ? v.extraPrice : v.extraPrice02)}</span>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    {item.properties && item.properties.showCalories &&
                                        <div className="p-margin-bottom-5">{trans('res.mgt.calories')}: {item.properties.calories}</div>
                                    }
                                    <div className="c-food-item-desc">{item.descs[selectedLocale] ? item.descs[selectedLocale] : item.descs[defaultLocale]}</div>
                                    {(item.tagAllergens.length > 0 || item.tagIngredients.length > 0) &&
                                        <div className="p-margin-top-5">
                                            {item.tagAllergens.map(tag => {
                                                return (
                                                    <Tippy
                                                        content={tag.trans.descs && tag.trans.descs[selectedLocale] ? tag.trans.descs[selectedLocale] : tag.trans.descs[defaultLocale]}
                                                        animation="fade"
                                                        placement="top"
                                                        trigger="mouseenter focus"
                                                        interactive={true}
                                                        arrow={true}
                                                        maxWidth='none'
                                                        duration={[350, 200]}
                                                    >
                                                        <span className="item-tag item-tag-red" style={{ backgroundColor: resConfig.resTagAllergenColor }}>{tag && tag.name ? tag.name : ''}</span>
                                                    </Tippy>
                                                );
                                            })}
                                            {item.tagIngredients.map(tag => {
                                                return (
                                                    <Tippy
                                                        content={tag.trans && tag.trans.descs && tag.trans.descs[selectedLocale] ? tag.trans.descs[selectedLocale] : tag.trans.descs[defaultLocale]}
                                                        animation="fade"
                                                        placement="top"
                                                        trigger="mouseenter focus"
                                                        interactive={true}
                                                        arrow={true}
                                                        maxWidth='none'
                                                        duration={[350, 200]}>
                                                        <span className="item-tag item-tag-blue" style={{ backgroundColor: resConfig.resTagIngredientColor }}>{tag && tag.name ? tag.name : ''}</span>
                                                    </Tippy>
                                                );
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div key={"item_clear_" + item.id} className="c-food-empty-col"></div>
            </React.Fragment>
        );
    }


    loadMenus = (items) => {
        return items.filter(
            (i) =>
                i.enabled
        );
    };

    loadFoods = (items, menu) => {
        return menu === null ? items : items.filter((i) => i.menuIds.includes(menu));
    };

    compareTimes = (items, time) => {
        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                let item = items[i];

                let startTime = item.s ? item.s : "00:00";
                let endTime = item.e ? item.e : "23:59";
                let startParts = startTime.split(":");
                let start =
                    Number.parseInt(startParts[1]) + Number.parseInt(startParts[0]) * 60;
                let endParts = endTime.split(":");
                let end =
                    Number.parseInt(endParts[1]) + Number.parseInt(endParts[0]) * 60;

                if (time >= start && time <= end) return true;
            }
            return false;
        } else {
            return true;
        }
    }

    optionValueIdsTranslate = (item, optValueIds) => {
        const defaultLocale = localStorage.getItem('defaultLocale');
        const selectedLocale = localStorage.getItem('selectedLocale');

        const variantOpts = item.options.filter(o => o.type === PRODUCT_OPTION_TYPE.variant);

        let optValueNames = [];

        optValueIds.map((oValId, oValIdx) => {
            const optValue = variantOpts.length > 0 ? variantOpts[oValIdx].values.find(v => v.id === oValId) : null;

            optValue && optValueNames.push(optValue.trans.names[selectedLocale] ? optValue.trans.names[selectedLocale] : optValue.trans.names[defaultLocale] ? optValue.trans.names[defaultLocale] : optValue.name);
        });

        return optValueNames.join(', ');
    }

    render() {
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        let wrapperClass = classNames("layout-wrapper", {
            "layout-wrapper-static": this.state.layoutMode === "static",
            "layout-wrapper-active": this.state.mobileMenuActive,
            "layout-menu-horizontal": this.state.layoutMode === "horizontal",
        });

        const languages = localStorage.getItem('enabledLocales');
        const applicationUrl = localStorage.getItem('resApplicationUrl');
        const selectLocale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : localStorage.getItem('defaultLocale');
        const cFlagImage = this.getFlagPath(selectLocale);
        const activeMenu = localStorage.getItem('selectedMenu') ? parseInt(localStorage.getItem('selectedMenu')) : null;

        const currency = localStorage.getItem('currencySymbol');
        const selectedLocale = localStorage.getItem('selectedLocale');
        const defaultLocale = localStorage.getItem('defaultLocale');

        const resConfig = localStorage.getItem('resConfig') ? JSON.parse(localStorage.getItem('resConfig')) : {};

        let addHeight = 0;
        if (resConfig.showResName && resConfig.resName)
            addHeight = addHeight + 70
        if (resConfig.showBrandingName && resConfig.brandingName)
            addHeight = addHeight + 70
        let layoutPadding = ((this.state.topbarHeight + addHeight) + 'px 15px 0px 15px');
        return (
            <div className={wrapperClass}>
                <div className="layout-main customer">
                    <div className="layout-topbar customer" ref={(el) => this.topBar = el}>
                        <Dialog className="lang-dialog" header={trans('res.menu.change_lang')} visible={this.state.languageDialogVisible} modal={true} onHide={() => this.setState({ languageDialogVisible: false })}
                            footer="">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    {languages && languages.split(',').map((option) => {
                                        return <div key={option} className="p-col-12 language-button">
                                            <button style={{ display: "inline-flex", width: "100%" }} onClick={() => { this.changeLanguage(option); this.setState({ languageDialogVisible: false, cFlagImage: this.getFlagPath(option), language: option }) }}>
                                                <img width={27} alt={this.state.activeLocale} src={this.getFlagPath(option)} />
                                                <label style={{ margin: "auto", paddingLeft: "3px" }}>{this.getLangName(option)}</label>
                                            </button>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </Dialog>

                        <QRCodeForm ref={el => this.QRCodeForm = el}
                            href={`${applicationUrl}menu`}
                            trans={trans}
                        />

                        <div className="topbar-action sticky">
                            <button className="p-button p-button-secondary p-button-language p-margin-right-5" style={{ float: 'left' }} title={trans('res.menu.change_lang')} onClick={() => this.setState({ languageDialogVisible: true })}>
                                <img width={35} alt={this.state.language} src={cFlagImage} />
                            </button>
                            <Button icon="pi-md-crop-free" tooltip={trans('res.mgt.show_qr')} tooltipOptions={{ position: 'bottom' }} className="p-button-info p-float-left" style={{ borderRadius: '3px' }} onClick={e => this.QRCodeForm.show({ code: 'menuQR' }, `${applicationUrl}menu`)} />
                        </div>

                        <div className="topbar-header p-c" style={{ backgroundColor: resConfig.resTopbarBackgroundColor, color: resConfig.resTopbarTextColor, paddingTop: '85px' }}>
                            {resConfig.resLogo &&
                                <img className="topbar-header-logo" height={resConfig.resLogoHeight ? resConfig.resLogoHeight : 65} alt="LOGO" src={`/restaurant/assets/restaurant_logo.png`} />
                            }
                            {resConfig.showResName &&
                                <h1 className="topbar-header-title">{resConfig.resName}</h1>
                            }
                            {resConfig.showBrandingName &&
                                <h2 className="topbar-header-branding">{resConfig.brandingName}</h2>
                            }
                        </div>
                    </div>
                    {/* <CustomerOrderTopbar layoutMode={this.state.layoutMode}
                            config={resConfig ? resConfig : {}}
                            useFor={USE_FOR_PURPOSE.res_dine_in}
                            cLang={this.state.language} changeLanguage={(e) => this.changeLanguage(e)}
                            isOrderPage={true} setHeight={(e) => this.setState({ topbarHeight: e })}
                            table={this.state.table} hasOrders={this.state.orders.length > 0}
                            onCallWaiter={() => this.callWaiter()}
                            requestCheck={() => this.askForConfirmAction(TABLE_REQUEST_STATUS.check_request)}
                            handleMakePayment={() => this.makePayment()}
                            onCartClick={() => this.setState({ frmCartVisible: true })} cartItemCount={this.getTotalCartItemCount()}
                            trans={trans}
                        /> */}
                    <div className="layout-content menu-wrapper" id="layout-content-container">
                        <LoadingOverlay />
                        {/* <MenuOrdering cLang={this.state.language} trans={trans}
                                config={resConfig ? resConfig : {}}
                                useFor={USE_FOR_PURPOSE.res_dine_in}
                                setCategories={(e) => this.setState({ dishCategories: e })}
                                table={this.state.table} currency={currency} draftOrderId={this.state.draftOrder.id}
                                padding={layoutPadding}
                                hasOrders={this.state.orders.length > 0} /> */}
                        {(() => {
                            let menus = this.state.data.menus;

                            let activeMenu = this.state.activeMenu
                            if (menus.length > 0) {
                                let needle = 0
                                if (activeMenu) {
                                    needle = menus.findIndex((mi) => mi.id === activeMenu)
                                    if (needle === -1) {
                                        needle = 0
                                    }
                                }
                                activeMenu = menus[needle].id
                                localStorage.setItem('selectedMenu', menus[needle].id)
                            }
                            if (menus.length > 0) {
                                return (
                                    <React.Fragment>
                                        <div className="p-col-12" style={{ padding: layoutPadding }}>
                                            <div className="p-grid form-group p-margin-top-0 c-menu">
                                                <div className="p-col-12" style={{ position: "relative" }}>
                                                    <div className="c-menu-wrapper">
                                                        {menus.map((item) => this.menuTemplate(item, activeMenu, selectedLocale, defaultLocale))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-hr" />
                                    </React.Fragment>
                                );
                            }
                        })()}

                        <div className="p-col-12 category-wrapper">
                            {this.state.data && this.state.data.menus && this.loadMenus(this.state.data.menus).length > 0 &&
                                this.state.data.categories && this.state.data.categories.map((category) => {
                                    let items = this.loadFoods(category.foodProducts, activeMenu ? activeMenu : this.state.activeMenu);
                                    if (items.length > 0) {
                                        return (
                                            <Panel key={"cate_" + category.id} header={category.trans ? category.trans.names[selectedLocale] ? category.trans.names[selectedLocale] : category.trans.names[defaultLocale] ? category.trans.names[defaultLocale] : category.name : category.name} className="customer p-margin-top-10" toggleable={true} headerClassName="p-accordion-header-customer">
                                                {category.trans &&
                                                    <p className="p-margin-top-0 p-margin-bottom-10">{category.trans && category.trans.descs[selectedLocale] ? category.trans.descs[selectedLocale] : category.trans.descs[defaultLocale]}</p>
                                                }
                                                <div className="c-food-wrapper">
                                                    {items.map((item, idx) => this.foodTemplate(item, trans, category, USE_FOR_PURPOSE.res_dine_in))}
                                                </div>
                                            </Panel>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces("message")(StandAloneMenu);
